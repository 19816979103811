<template>
    <v-container>
        <VTopToolbar title="Instruction" :showBack="true" id="instruction-toolbar">
            
            <!-- Override right nav button -->
            <template slot="right">
                <v-icon @click="navTo('time-entries')">mdi-clock-outline</v-icon>
                 
                <v-btn icon @click="navTo('comments')">
                    <v-badge
                        :value="commentCount > 0"
                        :content="commentCount"
                        color="error lighten-1"
                        offset-x="12"
                        offset-y="14"
                    >
                        <v-icon>mdi-comment-outline</v-icon>
                    </v-badge>                    
                </v-btn>                
                 
                 <v-icon :disabled="!phaseInfo" @click="showActionSheet">
                     mdi-dots-horizontal
                </v-icon>
            </template>
        </VTopToolbar>

        <div v-show="loading">
            <v-skeleton-loader type="paragraph"></v-skeleton-loader>
        </div>

        <div v-if="!loading">

            <h3>
                {{ title }}
                <v-icon v-if="bookmarked" small>mdi-star</v-icon>
            </h3>            

            <div class="mb-2 grey--text text--darken-2">
                <div class="text-truncate">
                    {{typeErsOrDamages}} &ndash;
                    {{phaseName}} &ndash;
                    {{reference || "No reference"}}
                </div>
            </div>

            <div class="mb-2" v-if="engineerName">
                <label class="section-label">Engineer</label>
                <div>{{engineerName}}</div>
            </div>

            <div class="mb-2" v-if="engineerName">
                <label class="section-label">Instruction date</label>
                <div>{{$moment(instructionDate).format('DD/MM/YYYY HH:mm')}}</div>
            </div>

            <div class="mb-2" v-if="engineerName">
                <label class="section-label">Incident location</label>
                <div><a :href="mapUrl">{{incidentLocation}}</a></div>
            </div>

            <div class="mb-2" v-if="engineerName">
                <label class="section-label">Remedy number</label>
                <div>{{remedyNumber}}</div>
            </div>
            
            <v-divider class="mb-3"></v-divider>

            <div class="subtitle-2">
                Complete the following details and attach any evidence.
            </div>

            <v-expansion-panels flat tile class="mt-5 mb-5 grey-panels" v-model="openedPanel">

                <v-expansion-panel>
                    <v-expansion-panel-header class="panel-header">Attachments</v-expansion-panel-header>
                    <v-expansion-panel-content
                        id="documents-panel"
                        :eager=true
                    >
                        <related-documents
                            v-if="id"
                            :id="id"
                            :canEdit="canEdit"
                            :showLabel=false
                            uploadIcon="mdi-camera-plus-outline"
                            path="Investigation"
                            v-on:uploadAllowedChanged="onUploadAllowedChanged"
                        >
                            <template slot="placeholder">
                                <div>
                                    <div v-if="canEdit">Tap the <v-icon>mdi-camera-plus-outline</v-icon>&nbsp; icon to upload a photo/video.</div>
                                    <div v-if="!canEdit">No documents</div>
                                </div>
                            </template>
                        </related-documents>            
                    </v-expansion-panel-content>
                </v-expansion-panel>
                 
                <v-expansion-panel>
                    <v-expansion-panel-header-editable
                        class="panel-header" 
                        :canEdit="canEdit"
                        v-on:editClicked="editIncidentDetails()"
                        :errorCount="incidentErrorCount"
                    >
                        Incident details
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <IncidentDetails v-bind="incidentDetailsData"></IncidentDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel>
                    <v-expansion-panel-header-editable
                        class="panel-header" 
                        :canEdit="canEdit"
                        v-on:editClicked="editFaultDetails()"
                        :errorCount="faultErrorCount"
                    >
                        Fault location &amp; site details
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <FaultDetails v-bind="faultDetailsData"></FaultDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel>
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editRepairDetails()"
                        :errorCount="repairsErrorCount"
                    >
                        Repair details
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <RepairDetails v-bind="repairs"></RepairDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel v-if="isRti">
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editRtiIncidentDetails()"
                        :errorCount="rtiIncidentErrorCount"
                    >
                        RTI incident details
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <RtiIncidentDetails v-bind="rtiIncident"></RtiIncidentDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel>
                    <v-expansion-panel-header-editable
                        class="panel-header"
                        :canEdit="canEdit"
                        v-on:editClicked="editLiabilityDetails()"
                        :errorCount="liabilityErrorCount"
                    >
                        Liability details
                    </v-expansion-panel-header-editable>
                    <v-expansion-panel-content>
                        <LiabilityDetails v-bind="liability"></LiabilityDetails>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
                <v-expansion-panel>
                    <v-expansion-panel-header class="panel-header">Materials</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <payment-list v-if="id" :id="id"/>
                    </v-expansion-panel-content>
                </v-expansion-panel>
                
            </v-expansion-panels>

            <v-btn
                block
                large
                color="primary"
                v-if="primaryAction && canProgress"
                class="mt-6"
                @click="primaryAction.handler()"
                :disabled="!isValid"
            >
                <v-icon left>{{primaryAction.icon}}</v-icon>
                {{primaryAction.text}}
            </v-btn>            

            <v-btn
                color="#e10a0a"
                elevation="6"
                fab
                small
                dark
                v-if="id && cameraVisible"
                id="fab-camera"
                @click="cameraClicked()"
            >
                <v-icon>mdi-camera</v-icon>
            </v-btn>

        </div>
        
    </v-container>
</template>

<script>
import tasksAgent from "../Tasks/tasksAgent.js";
import instructionsAgent from "./instructionsAgent.js";
import FaultDetails from "./Sections/FaultAndSiteDetails";
import IncidentDetails from "./Sections/IncidentDetails";
import LiabilityDetails from "./Sections/LiabilityDetails";
import RepairDetails from "./Sections/RepairDetails";
import RtiIncidentDetails from "./Sections/RtiIncidentDetails";
import bookmarksAgent from "../Bookmarks/bookmarksAgent";
import sharedoMenuService from "../../services/sharedo/sharedoMenuService.js";
import sharedoActionService from "../../services/sharedo/sharedoActionService.js";
import { SharedoProfile } from "@sharedo/mobile-core";
import VBtnSection from "../../components/VBtnSection.vue";
import VExpansionPanelHeaderEditable from "../../components/VExpansionPanelHeaderEditable.vue";
import mapUrlGeneratorFactory from "@/services/mapping/mapUrlGeneratorFactory";
import { fromIncident } from "@/util/mappers/locationMapper";
import comments from "@/views/Comments/commentsAgent";

// Lazy load dialogs
const TaskDetailForm = () => import("../Tasks/TaskDetailForm.vue");
const FaultAndSiteDetailsForm = () => import("./Forms/FaultAndSiteDetailsForm.vue");
const IncidentDetailsForm = () => import("./Forms/IncidentDetailsForm.vue");
const LiabilityDetailsForm = () => import("./Forms/LiabilityDetailsForm.vue");
const PaymentList = () => import("../Payments/PaymentList.vue");
const RelatedDocuments = () => import("../RelatedDocuments/RelatedDocuments.vue");
const RepairDetailsForm = () => import("./Forms/RepairDetailsForm.vue");
const RtiIncidentDetailsForm = () => import("./Forms/RtiIncidentDetailsForm.vue");

export default {
    components: {
        TaskDetailForm,
        FaultDetails,
        IncidentDetails,
        LiabilityDetails,
        RepairDetails,
        RtiIncidentDetails,
        FaultAndSiteDetailsForm,
        IncidentDetailsForm,
        LiabilityDetailsForm,
        PaymentList,
        RelatedDocuments,
        RepairDetailsForm,
        RtiIncidentDetailsForm,
        VBtnSection,
        VExpansionPanelHeaderEditable,
    },

    data: function () {
        return {
            loading: true,
            openedPanel: null,
            id: null,
            reference: null,
            phaseName: null,
            title: null,
            description: null,
            typeSystemName: null,
            typeErsOrDamages: null,
            engineerName: null,
            incident: {
                causeId: null,
                causeText: null,
                typeId: null,
                typeText: null,
                description: null,
                subLocationId: null,
                subLocationText: null,
                mandatoryFieldsCompletedAt: null,
                relatesToPiaId: null,
                relatesToPiaText: null,
                piaGridReference: null,
                piaWhereaboutsId: null,
                piaExchangeCode: null,
                piaOverheadUndergroundId: null,
                piaOverheadUndergroundText: null,
            },
            ersIncident: {
                businessPremiseId: null,
                businessPremiseText: null,
                dropCableId: null,
                dropCableText: null,
                cableTypeId: null,
                cableTypeText: null,
                cableTypeDetail: null,
            },
            rtiIncident: {
                weatherConditionsId: null,
                weatherConditionsText: null,
                roadConditionsId: null,
                roadConditionsText: null,
                wasInDarkId: null,
                wasInDarkText: null,
                wasStreetLightingId: null,
                wasStreetLightingText: null,
                distanceToNearestStreetLight: null,
                regNumber: null,
                isDriverOwnerId: null,
                isDriverOwnerText: null,
                driverDetails: null,
                policeReportRefNumber: null,
                vehicleTowInfo: null,
                additionalInfo: null,
            },
            faultDetailsData: {
                incidentFaultLocation: null,
                incidentDidYouAttendSite: null,
                incidentSiteDetails: null,
            },
            repairs: {
                repairsCompletedDate: null,
                repairTypeId: null,
                repairTypeText: null,
                repairDetails: null,
            },
            liability: {
                liabilityWitnessId: null,
                liabilityWitnessText: null,
                liabilityDetails: null,
            },
            canEdit: false,
            canProgress: false,
            participantActions: null,
            phaseInfo: null,
            primaryAction: null,
            primaryTransition: null,
            bookmarkingEnabled: false,
            bookmarked: false,
            cameraVisible: false,
            commentCount: 0,
            changesCommitted: false,    // When user has saved a change which makes form valid, save a 'mandatoryFieldsComplete' attribute
        };
    },

    computed: {
        isMine: function () {
            return this.primaryOwnerId === SharedoProfile.profile.userId;
        },

        isErs: function () {
            return (this.typeSystemName || "").endsWith("ers");
        },

        isPia: function () {
            return this.incident.relatesToPiaId === 500000181;  // Yes
        },

        isRti: function () {
            return this.incident.causeId === 313717;    // RTI
        },

        incidentDetailsData: function() {
            return Object.assign(
                {
                    typeSystemName: this.typeSystemName,
                },
                this.incident,
                this.ersIncident);
        },

        isValid: function() {
            var i = 0;
            i += this.incidentErrorCount;
            i += this.rtiIncidentErrorCount;
            i += this.faultErrorCount;
            i += this.repairsErrorCount;
            i += this.liabilityErrorCount;
            return i === 0;
        },

        incidentErrorCount: function() {
            var i = 0;
            if (!this.incident.causeId) i++;
            if (!this.incident.typeId) i++;
            if (!this.incident.description || this.incident.description === "<p></p>") i++;
            if (!this.incident.subLocationId) i++;
            if (!this.incident.relatesToPiaId) i++;

            if (this.isPia && !this.incident.piaGridReference) i++;
            if (this.isPia && !this.incident.piaWhereaboutsId) i++;
            if (this.isPia && !this.incident.piaExchangeCode) i++;
            if (this.isPia && !this.incident.piaOverheadUndergroundId) i++;

            if (this.isErs && !this.ersIncident.businessPremiseId) i++;
            if (this.isErs && !this.ersIncident.dropCableId) i++;
            if (this.isErs && !this.ersIncident.cableTypeId) i++;
            return i;
        },

        rtiIncidentErrorCount: function() {
            var i = 0;
            if (!this.isRti) return i;
            if (!this.rtiIncident.weatherConditionsId) i++;
            if (!this.rtiIncident.roadConditionsId) i++;
            if (!this.rtiIncident.wasInDarkId) i++;
            return i;
        },

        faultErrorCount: function() {
            var i = 0;
            return i;
        },

        repairsErrorCount: function() {
            var i = 0;
            if (!this.repairs.repairTypeId) i++;
            if (!this.repairs.repairDetails) i++;
            return i;
        },

        liabilityErrorCount: function() {
            var i = 0;
            if (!this.liability.liabilityWitnessId) i++;
            return i;
        },

        mapUrl: function() {
            const urlGenerator = mapUrlGeneratorFactory.get(this.locationDetails);

            return urlGenerator ? urlGenerator.generate() : "";
        }
    },
    
    watch: {
        isValid: function(val) {

            // General idea - once all mandatory fields are completed, save an additional attribute against
            // the instruction with the current date/time. This is because there is no transition at this point
            // but we want to be able to report on it. Don't update the field if already set. Mobile app only.

            // Has user just saved a section and the instruction has become valid?
            if (this.changesCommitted && val === true && !this.incident.mandatoryFieldsCompletedAt) {

                console.log("Setting mandatory fields completed date");

                // Set current date
                const now = this.$moment();
                this.incident.mandatoryFieldsCompletedAt = now.format("YYYY-MM-DD HH:mm:ss");
    
                var l = this.$coreUi.loading();
                
                // Load workItem
                instructionsAgent.getInstruction(this.id).then((result) => {

                    // Set fields and save
                    result.aspectData.formBuilder.formData["vm-incident-mandatory-fields-completed-at"] = this.incident.mandatoryFieldsCompletedAt;

                    // Don't invoke these aspect savers (they could fail)
                    delete result.aspectData.odsEntityPicker;
                    delete result.aspectData.instructionWorkTypeDetails;
                    delete result.aspectData.instructionB2BDetails;
                    delete result.aspectData.locationCoordinate;
                    
                    instructionsAgent.saveInstruction(this.id, result).then(() => {
                        l.dismiss();
                    }).catch(console.error);

                }).catch(console.error);
            }
        },
    },

    mounted: function () {
        this.id = this.$route.params.id;

        this.load();
    },

    methods: {
        async load() {
            await Promise.all([this.loadInstruction(), this.loadCommentCount()]);
        },
        async loadCommentCount() {
            try {
                const response = await comments.getComments(this.id, 1, 1);
                this.commentCount = response.totalRows;
            } catch (error) {
                console.error(error);
            }
        },

        parseMultiSelectOption (input, path, property) {
            var data = input[path];
            return data == null ? [] : data.map(x => x[path + "." + property]);
        },

        loadInstruction () {
            this.loading = true;

            instructionsAgent.getInstructionEnriched(this.id).then(response => {
                var item = response.results[0];
                if (!item) throw "Sharedo not found: " + this.id;

                var data = item.data;

                this.reference = data.reference;
                this.phaseName = data["phase.name"];
                this.typeSystemName = data["type.systemName"];
                this.typeErsOrDamages = data["type.name"].split(" ").pop();
                this.title = data.title;
                this.description = data["description.html"];

                // Basic details
                this.engineerName = data["roles.vm-engineer.ods.name"];
                this.instructionDate = new Date(data["keyDates.kd-instruction-date.taskDueDate.date.local.value"]);
                this.incidentLocation = data["incidentDetailsLocation.location.formatted"];                
                this.remedyNumber = data["form-b269621a-e7eb-4e97-bac9-237b99af0f04-RemedyDetails.vm-reference-text"];

                // incident location data
                this.locationDetails = fromIncident(data);

                // Incident details
                this.incident.causeId = data["incidentDetailsCause.cause.id"];
                this.incident.causeText = data["incidentDetailsCause.cause.name"];
                this.incident.typeId = data["incidentDetailsType.type.id"];
                this.incident.typeText = data["incidentDetailsType.type.name"];
                this.incident.description = data["incidentDetailsDescription.description.html"];
                this.incident.subLocationId = data["form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-details-extended-sub-loc-lov.id"];
                this.incident.subLocationText = data["form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-details-extended-sub-loc-lov.name"];
                this.incident.mandatoryFieldsCompletedAt = data["form-bff79397-a148-4088-b7bd-a38b2f3be6ce-IncidentDetailsExtended.vm-incident-mandatory-fields-completed-at"];
                this.incident.relatesToPiaId = data["form-pia-details.does-this-relate-to-pia.id"];
                this.incident.relatesToPiaText = data["form-pia-details.does-this-relate-to-pia.name"];
                this.incident.piaGridReference = data["form-pia-details.vm-pia-grid-reference"];
                this.incident.piaWhereaboutsId = data["form-pia-details.vm-pia-whereabouts-id"];
                this.incident.piaExchangeCode = data["form-pia-details.vm-pia-exchange-code"];
                this.incident.piaOverheadUndergroundId = data["form-pia-details.vm-pia-overhead-underground.id"];
                this.incident.piaOverheadUndergroundText = data["form-pia-details.vm-pia-overhead-underground.name"];

                // ERS incident details
                this.ersIncident.businessPremiseId = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-business-premise-bool.id"];
                this.ersIncident.businessPremiseText = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-business-premise-bool.name"];
                this.ersIncident.dropCableId = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-drop-cable-rel-bool.id"];
                this.ersIncident.dropCableText = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-drop-cable-rel-bool.name"];
                this.ersIncident.cableTypeId = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-lov.id"];
                this.ersIncident.cableTypeText = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-lov.name"];
                this.ersIncident.cableTypeDetail = data["form-06b84f61-30ef-4a3e-a33c-6078efaee1ac-IncidentDetails.vm-instruction-dispute-claimant-pd-ers-details-cable-type-detail-text"];

                // RTI incident details
                this.rtiIncident.weatherConditionsId = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-weather-conditions-lov.id"];
                this.rtiIncident.weatherConditionsText = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-weather-conditions-lov.name"];
                this.rtiIncident.roadConditionsId = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-road-conditions-lov.id"];
                this.rtiIncident.roadConditionsText = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-road-conditions-lov.name"];
                this.rtiIncident.wasInDarkId = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-in-dark-bool.id"];
                this.rtiIncident.wasInDarkText = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-in-dark-bool.name"];
                this.rtiIncident.wasStreetLightingId = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-street-lighting-bool.id"];
                this.rtiIncident.wasStreetLightingText = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-street-lighting-bool.name"];
                this.rtiIncident.distanceToNearestStreetLight = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-nearest-light-int"];
                this.rtiIncident.regNumber = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vrn-text"];
                this.rtiIncident.isDriverOwnerId = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vehicle-owner-bool.id"];
                this.rtiIncident.isDriverOwnerText = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-vehicle-owner-bool.name"];
                this.rtiIncident.driverDetails = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-driver-details-text"];
                this.rtiIncident.policeReportRefNumber = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-police-report-ref-text"];
                this.rtiIncident.vehicleTowInfo = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-tow-info-text"];
                this.rtiIncident.additionalInfo = data["form-243bdb5e-fb02-4305-b199-93d0c87cf199-RTIIncidentDetails.vm-rti-details-additional-info-text"];

                // Fault location details
                this.faultDetailsData.incidentFaultLocation = data["form-vm-instruction-fault-location-site-details.vm-incident-fault-location"];
                this.faultDetailsData.incidentDidYouAttendSite = data["form-vm-instruction-fault-location-site-details.vm-incident-did-you-attend-site"];
                this.faultDetailsData.incidentSiteDetails = data["form-vm-instruction-fault-location-site-details.vm-incident-site-details"];

                // Repair details
                this.repairs.repairsCompletedDate = data["form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-completed-date"];
                this.repairs.repairTypeId = data["form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-type-lov.id"];
                this.repairs.repairTypeText = data["form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-type-lov.name"];
                this.repairs.repairDetails = data["form-c2b97681-f7ab-4447-97ac-3d9017dea0d4-RepairDetails.vm-repair-details-repair-details-text"];

                // Liability details
                this.liability.liabilityWitnessId = data["form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-lov.id"];
                this.liability.liabilityWitnessText = data["form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-lov.name"];
                this.liability.liabilityDetails = data["form-28918794-9f8b-41a4-bcdc-3b3231c1eebb-LiabilityDetails.vm-liability-details-witness-details-text"];

                bookmarksAgent.isBookmarked(this.id).then(({featureEnabled, isBookmarked}) => {
                    this.bookmarkingEnabled = featureEnabled,
                    this.bookmarked = isBookmarked
                }).catch(console.error);

                this.loading = false;
            }).catch(console.error);

            tasksAgent.getPermissions(this.id).then(perm => {
                this.permissions = perm;

                // TODO is closed?
                this.canEdit = perm.includes("core.sharedo.update");
                this.canProgress = perm.includes("core.sharedo.progress.milestone");

            }).catch(console.error);

            tasksAgent.getPhaseInfo(this.id).then(info => {
                this.phaseInfo = info;

                var transOpts = {
                    sharedoId: this.id,
                    callback: this.afterTransitionTo.bind(this),
                }

                // Get primary action
                this.primaryAction = this.phaseInfo.availableTransitions
                    .filter(t => t.isUserDriven && (t.isOptimumPath || this.phaseInfo.availableTransitions.length === 1))
                    .map(t => {
                        var transTo = Object.assign({}, t, transOpts);

                        return {
                            text: t.name,
                            icon: t.toPhaseIconClass || "mdi-fast-forward-outline",
                            handler: sharedoActionService.confirmTransitionTo.bind(this, transTo),
                        };
                    })[0];

            }).catch(console.error);
        },

        showActionSheet () {
            var items = sharedoMenuService.getSharedoActionsMenu({
                id: this.id,
                rolePermissions: this.permissions,
                phaseInfo: this.phaseInfo,
                participantActions: this.participantActions,
                afterTakeOwnership: this.afterTakeOwnership.bind(this),
                afterTransitionTo: this.afterTransitionTo.bind(this),
                bookmarking: {
                    enabled: this.bookmarkingEnabled,
                    bookmarked: this.bookmarked,
                    onBookmarkChange: this.onBookmarkChanged
                }
            });

            this.$coreUi.actionSheet({
                items: items
            });
        },

        afterTakeOwnership () {

            // Reload permissions/actions
            this.loadInstruction();
        },

        afterTransitionTo (opts) {
            if (opts && opts.shouldCloseUi)
                this.$router.back();
            else
                this.loadInstruction();
        },

        editTaskDescription () {
            this.$coreUi.dialog(TaskDescriptionForm,
                {
                    id: this.id,
                    description: this.description,
                },
                {
                    closing: (result) => {
                        if (result) this.loadInstruction();
                    }
                });
        },

        editIncidentDetails () {
            // Include ERS incident fields
            var model = Object.assign(
                {
                    id: this.id,
                    typeSystemName: this.typeSystemName,
                },
                this.incident,
                this.ersIncident);

            this.$coreUi.dialog(IncidentDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.onChangesCommitted();
                    }
                });
        },

        editRtiIncidentDetails () {
            var model = Object.assign({ id: this.id }, this.rtiIncident);

            this.$coreUi.dialog(RtiIncidentDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.onChangesCommitted();
                    }
                });
        },

        editFaultDetails () {
            var model = Object.assign({ id: this.id }, this.faultDetailsData);

            this.$coreUi.dialog(FaultAndSiteDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.onChangesCommitted();
                    }
                });
        },

        editRepairDetails () {
            var model = Object.assign({ id: this.id }, this.repairs);

            this.$coreUi.dialog(RepairDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.onChangesCommitted();
                    }
                });
        },

        editLiabilityDetails () {
            var model = Object.assign({ id: this.id }, this.liability);

            this.$coreUi.dialog(LiabilityDetailsForm,
                model,
                {
                    closing: (result) => {
                        if (result) this.onChangesCommitted();
                    }
                });
        },

        onChangesCommitted: function() {

            this.changesCommitted = true;

            this.loadInstruction();
        },

        navTo (subPage) {
            this.$router.push({ path: `${window.location.pathname}/${subPage}` });
        },

        onBookmarkChanged(bookmarked) {
            this.bookmarked = bookmarked
        },

        onUploadAllowedChanged(state) {
            this.cameraVisible = state;
        },

        cameraClicked() {
            this.openedPanel = 0;   // Related docs

            setTimeout(() => {
                let element = document.getElementById("input-upload");
                if (element) {
                    element.click();
                }
            }, 200);
        },
    },
};
</script>

<style>
#instruction-toolbar .spacer ~ .v-card__actions {
    margin-left: -72px;
}

/* Put document menu in panel header */
#documents-panel div.header-btns {
    position: absolute;
    top: 10px;
    right: 45px;
}
</style>